.footerMainContainer{
    width:100%;
    margin-top: 8rem;
    margin-bottom:0;
    background-color: #f6efddf8;
    border-top-left-radius :150px;
}

.footerWrapper{
    width:90%;
    padding-top:3rem;
    margin: 0 auto 0;
    display: grid;
    grid-template-columns: 10% 35% 35% 20%;
}

.footerGridContentsOne{
    text-align: left;
    padding: 0 auto;
}

.footerGridContentsOne h5{
    text-align: left;
    font-weight: bold;
    color:#ffb812;
    text-shadow: 0 0 1px #000000, 0 0 1px #000000;
    text-decoration: underline;
}

.footerGridContentsOne p {
    font-size: 16px;
    line-height: 20px;
    margin:0;
}

.address{
    display: block;
    font-style: italic;
}

.addressEmail{
    color:ffb812;
}

.footerGridContentsTwo{
    text-align: center;
}

#footerGridContentsTwoli{
    color:black;
    text-decoration: none;
}

#footerGridContentsTwoli:hover{
    color:#ffb812 ;
}

.footerGridContentsThree{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footerFacebookIcon{
    margin-right: 2rem;
}

@media (max-width: 868px) {
    .footerWrapper{
        width:90%;
        padding-top:3rem;
        margin: 0 auto 0;
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .footerGridContentsOne, .footerGridContentsTwo {
        text-align: left;
        padding: 0 auto;
        width:70%;
        margin:auto;
        border-bottom: 1px solid #ffb812;
        padding-bottom:1rem;
        padding-top:1rem;
    }

    .footerGridContentsThree{
        margin-top: 1rem;
        display: flex;
        width:80%;
        padding-left: 10%;
        flex-direction: row;
        justify-content: space-around;
        padding-bottom: 2rem;
    }
}
