.blogBanner{
    width:100%;
    height:35vh;
}

.blogBannerContainer{
    position: relative;
}

.blogBannerTextContainer{
    position:absolute;
    right:45%;
    top:25%;
    left:5%;
    color:#ffb812;
    font-size: 160%;
    letter-spacing: 2px;
    font-weight: 900;
    text-shadow: 0 0 3px #000000, 0 0 5px #000000;
}

.sectionMainContainer{
    width:90%;
    margin:2% auto;
}

.blogCardsRow{
    width:60%;
    margin:auto;
    margin-bottom: 2%;
}

#blogsHeader{
    background: linear-gradient(145deg, #ffb812, #eee36b); 
}

#modalHeading{
    background: linear-gradient(145deg, #ffb812, #eee36b); 
}

.blogModalMainImg{
    width:90%;
    margin:auto;
    height:60vh;
    display:flex;
    justify-content: center;
    align-items: center;
}

.blogModalTexT{
    margin:2% 3%;
}

.blogModalTexT h3, .blogModalTexT h4{
    color:#ffb812;
}