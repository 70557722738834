.accordion-button:not(.collapsed) {
    color: black;
    background: linear-gradient(145deg, #ffb812, #eee36b); 
    box-shadow: inset 0 -1px 0 #eee36b;
}

#AccordionHeading{
    transition:0.5s;
    font-weight: bold !important;
}

#AccordionHeading:hover{
    transform: translateY(-10px);
}