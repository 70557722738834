.classBanner{
    width:100%;
    height:35vh;
}

.classBannerContainer{
    position: relative;
}

.classBannerTextContainer{
    position:absolute;
    right:45%;
    top:25%;
    left:5%;
    color:#ffb812;
    font-size: 160%;
    letter-spacing: 2px;
    font-weight: 900;
    text-shadow: 0 0 3px #000000, 0 0 5px #000000;
}

.classesMainContainer{
    margin:3% auto;
    width:90%;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.modalBodyImgContainer{
    width: 90%;
    margin:auto;
    display:flex;
    justify-content: center;
    align-items: center;
}

.modalBodyImg{
    width:60%;
    height:auto;
    margin-bottom:2rem;
}

.contactUsButton, .downloadBrouchreButton{
    text-decoration: none;
    color:black;
}

.contactUsButton:hover{
 color:#fff;
}

@media (max-width: 868px) {}