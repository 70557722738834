.homeMainContainer{
    width:95%;
    margin:2% auto;
}

.homeLandingSection{
    width:100%;
    /* min-height:85vh; */
    margin:auto;
    display:grid;
    grid-template-columns: 30% 70%;
}

.homeLandingSectionLeft{
    display: grid;
    grid-template-rows: 55% 45%;
}

.homeLandingSectionLeftTop{
    width:100%;
    /* height:100%; */
    position:relative;
    overflow: hidden;
}

.homeLandingSectionLeftTopImg{
    width:80%;
    /* height:auto; */
    position: absolute;
    top:-12%;
    left:-5%;
}

.homeLandingSectionLeftBottom{
    display:block;
    width:100%;
    padding-top: 3%;
}

.homeLandingSectionLeftBottomH1{
    /* text-align: center; */
    font-size: 340%;
    font-weight: bold;
    font-family: 'Engagement', cursive;
    text-transform: capitalize;
    letter-spacing: 3px;
    padding-left: 4%;
}

.homeLandingSectionLeftBottomH3{
    /* text-align: center; */
    font-size: 140%;
    padding-top:6%;
    padding-left: 4%;
    color:rgb(41, 39, 39);
}

.homeLandingSectionRight{
    width:100%;
    /* height:100%; */
}

.homeLandingSectionRightImg{
    width:100%;
    /* height:90%; */
}
/* Testimononials Section */
.testimonyMainContainer{
    width:95%;
    margin:3% auto;
}

.homeNukaButtons{
    font-size: 2rem;
    background-color: transparent;
}

.tesimonialContentWrapper{
    width:90%;
    margin:auto; 
    position:relative;
    height:25rem;
}

.tesimonialContentBgImg{
    width:100%;
    height:100%;
}

.tesimonialContentTextContainer{
    position: absolute;
    top:3%;
    left:3%;
    bottom:3%;
    right:3%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tesimonialContentTextDetails{
    text-align: center;
    font-weight: 500;
}
.tesimonialContentTextName{
    font-weight: bold;
    font-size: 120%;
}
.tesimonialContentTextAge{
    font-weight: 500;
    font-size: 110%;
    margin-top: -.75rem;
}

@media only screen and (max-width: 1024px)  {
    .homeLandingSection{
        width:90%;
        height:auto;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        margin-top:12px;
    }

    .homeLandingSectionLeft{
        width:100%;
        margin: 1rem auto;
        display: flex;
        flex-direction: row;
        padding: 1rem auto;
        
    }
    .homeLandingSectionLeftTop{
        width:40%;
        position: relative;
    }
    
    .homeLandingSectionLeftTopImg{
        width:70%;
        position: absolute;
        top:-3%;
        left:-3%;
    }

    .homeLandingSectionLeftBottom{
        width:60%;
        height:100%;
    }

    .homeLandingSectionLeftBottomH1{
        font-size: 200%;
        font-weight: bold;
        font-family: 'Engagement', cursive;
        text-transform: capitalize;
        letter-spacing: 3px;
        padding-left: 4%;
    }

    .homeLandingSectionLeftBottomH3{
        /* text-align: center; */
        font-size: 100%;
        padding-top:3%;
        padding-left: 4%;
        color:rgb(41, 39, 39);
    }
}