@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Engagement&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100&display=swap');
*{
  padding:0;
  margin:0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

body{
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff;
}

body::-webkit-scrollbar {
  display: none;
}

.container{
  max-width: 90%;
  margin:auto;
}



.f_flex{
  display: flex;
}

a {
  text-decoration: none;
  color:black;
}

li{
  list-style: none;
}

.uppercase{
  text-transform: uppercase;
}

.capitalize{
  text-transform: capitalize;
}

button{
  border:none;
  outline:none;
  background-color: none;
}

.btn_shadow{
  padding:15px 22px;
  border-radius:6px;
  transition:0.5s;
  cursor: pointer;
  color:#000;
}

.btn_shadow{
  background: linear-gradient(145deg, #e2e8ec, #ffffff);
  box-shadow: 4px 4px 8px #cbcbcb, 4px -4px 8px #ffffff;
}

.btn_shadow:hover{
  background: linear-gradient(145deg, #ffb812, #eee36b);
  color:#fff;
  transform: translateY(-10px);
}

.row,
.left,
.right {
  width: 50%;
}
.top {
  margin-top: 20px;
}
.mtop {
  margin-top: 40px;
}
.heading {
  margin: 50px 0 50px 0;
}
.heading h4 {
  color: #ff9e01;
  letter-spacing: 2px;
  font-weight: 400;
  text-align: center;
}
.heading h1 {
  font-size: 50px;
  color: #3c3e41;
  text-align: center;
}
h1 {
  color: #3c3e41;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 35px;
}
p {
  color: #3c3e41;
}
.text-center {
  text-align: center;
}
.primary_color {
  color: #ffa601;
}
textarea,
input {
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  outline: none;
  border: 3px solid #e2e8ec;
  margin-bottom: 20px;
}
/*----------Footer---------*/
footer {
  margin: 20% 0 10% 0;
}
footer p {
  margin-top: 30px;
}
/*----------Footer---------*/
@media (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .left,
  .right {
    width: 100%;
  }
}


