

.aboutBannerContainer{
    position: relative;
    width:100%;
    height:55vh;
}

.aboutBanner{
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    width:100%;
    height:100%;
}

.aboutMainSection{
    width:90%;
    margin:2% auto;
    display:grid;
    grid-template-columns: 38% 60%;
    gap:2%;
}

.aboutImgContainer{
    padding: 1rem;
    display:flex;
    justify-content: center;
    align-items: center;
}

.aboutImg{
    width:18rem;
    height:18rem;
}

.aboutText{
    margin-top: 3rem;
    padding:1rem;
    font-size: 110%;
    line-height: 130%;
    text-align: justify;
    font-weight: 400%;
}

.AboutRegistrationCertificateContainer{
    width:90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AboutRegistrationCertificate{
    height: 50vh;
    width:auto;
}

@media (max-width: 868px) {
    .aboutMainSection{
        width:90%;
        display: flex;
        flex-direction: column;
    }

    .aboutImgContainer{
        padding: 1rem;
        display:flex;
        justify-content: center;
        align-items: center;
        height:50vh;
    }

    .aboutImg{
        height:48vh;
        width:auto;
    }
}