.freeBanner{
    width:100%;
    height:35vh;
}

.freeBannerContainer{
    position: relative;
}

.freeBannerTextContainer{
    position:absolute;
    right:45%;
    top:25%;
    left:5%;
    color:#ffb812;
    font-size: 160%;
    letter-spacing: 2px;
    font-weight: 900;
    text-shadow: 0 0 3px #000000, 0 0 5px #000000;
}

.freeCardContainer{
    width:90%;
    margin:auto;
}

.freeCardContentRow{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:1rem;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 2rem;
}

.freeCardLinks{
    color:#ffb812;
}