.contactMainContainer{
    width:90%;
    margin: 2% auto;
}

.contactGridWrapper{
    margin:auto;
    display:grid;
    grid-template-columns: 49% 49%;
    gap:2%;
    justify-content: center;
}

.contactImage{
    width:90%;
    margin:auto;
    height:100%;
}

.contactGridContentRight{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:auto;
}

.contactUsCard{
    color: black;
    background: linear-gradient(145deg, #ffb812, #eee36b); 
    box-shadow: inset 0 -1px 0 #eee36b;
    font-weight: bold;
    height:10rem;
    width:20rem;
    margin:auto;
    display:flex;
    align-items: center;
    justify-content: center;
    transition:0.5s;
    font-weight: bold !important;
    text-align: center;
    border-radius: 5px;
}

.contactUsCard:hover{
    transform: translateY(-10px);
}

@media only screen and (max-width: 1024px){
    .contactGridWrapper{
        width:60%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
    }

    .contactImage{
        width: 100%;
        margin:1rem auto;
    }

    .contactUsCard{
        margin:1rem auto;
        width: 100%;
        padding:1rem;
    }
}