.cardCarouselContainer{
    height:60vh;
    width:100%;
    margin:2% auto;

}

.homeCarouselCardsContainer{
    width:90%;
    height:60vh;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    margin:auto;
    background-color: #ffffff;
}

.homeCarouselCard{
    width:30%;
    height: auto;
    padding:1rem auto;
    border-radius: 8px;
    background-color: #ffffff;
    overflow: hidden;    
    border: 1px solid #ffb812;
}

.homeCarouselCard:hover{
    box-shadow: 0 0 5px 5px #eee36b;
}

.homeCarouselCardImg{
    width:100%;
    height:30vh;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.homeCarouselCardText{
    padding:1rem 1rem 1rem 1rem;
    height: 8rem;
    line-height: 18px;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: .5px;
    background: linear-gradient(145deg, #ffb812, #eee36b);        
}

.homeNukaButtons{
    font-size: 2rem;
    background:transparent;
}

.paging-item{
    display:none;
}

.homeFeaturesCardImg{
    height:12rem;
}


@media (min-width: 868px) {
    .cardCarouselContainerSmallscreen{
        display:none;
    }
}

@media (max-width: 868px) {
    .cardCarouselContainer{
        display:none;
        height:auto;
        padding:2rem;
    }

    .cardCarouselContainerSmallscreen{
        height:auto;
        margin:2%;
    }

    .homeCarouselCard{
        width:60%;
        height:auto;
        border-radius: 8px;
        background-color: #ffffff;
        overflow: hidden;
    }
    .homeCarouselCardImg{
        width:100%;
        height:auto;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    
    .homeCarouselCardText{
    padding:1rem 1rem 1rem 1rem;
    height:10rem;
    line-height: 18px;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: .5px;
    background: linear-gradient(145deg, #ffb812, #eee36b);        
    }
    
}